/* Outer container for components */
.drmMeetingApproval {
  padding: 0px 1rem !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.drmMeetingApproval__mainBox {
  width: 100%;
  /* height: 11.059rem; */
  background: #ffffff;
  box-shadow: 1.28262px 1.28262px 5.50103px rgba(20, 20, 20, 0.07);
  border-radius: 0.519rem;
  margin: 0.625rem 0px;
}

/* Left sideCard */
.drmMeetingApproval__sidecard {
  background: #c6c6c6;
  opacity: 0.8;
  padding: 0.938rem 0.938rem 0.625rem 0.938rem !important;
  border-top-left-radius: 0.519rem;
  border-bottom-left-radius: 0.519rem;
}

@media (min-width:325px) {
  .drmMeetingApproval__sidecard {
    padding: 1.563rem 0.938rem 0.625rem 0.938rem !important;
  }
}


.drmMeetingApproval__sidecardImgTop {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.drmMeetingApproval__sidecardTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  text-align: center;
  color: #808080;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.drmMeetingApproval__sidecardText {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #808080;
}

@media screen and (min-width: 768px) {
  .drmMeetingApproval__sidecardText {
    margin-bottom: 0.5rem;
  }
}

.drmMeetingApproval__sidecardButton {
  box-sizing: border-box;
  background: #3901ff;
  border: 0.026rem solid #3901ff;
  border-radius: 1.104rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.622rem;
  color: #ffffff;
  padding: 2px 11px;
  height: 100%;
}

.drmMeetingApproval__redirectArrow {
  align-self: center;
  width: 25px;
  height: 20px;
}

/* Right sideBox */

.drmMeetingApproval__rightBox {
  padding: 0.625rem !important;
}

.drmMeetingApproval__title {
  font-style: normal;
  font-weight: 600;
  font-size: 0.83rem;
  line-height: 1rem;
  color: #3901ff;
  margin-bottom: 0.3rem !important;
  position: absolute;
}

.drmMeetingApproval__desp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 0.563rem;
  color: #2b2b2b;
  margin: 0rem 0rem 0.5rem 0rem;
}

.drmMeetingApproval__despRow {
  display: flex;
  column-gap: 5px;
}

.drmMeetingApproval__despBox {
  display: flex;
  /* height: 1.763rem; */
  border-radius: 0.16rem;
  margin-bottom: 0.313rem;
  padding: 0px !important;
  width: -webkit-fill-available;
  align-items: anchor-center;
}

.drmMeetingApproval__despBoxName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.693rem;
  line-height: 0.563rem;
  color: #f27042;
  padding: 0.313rem;
  text-wrap-mode: nowrap;
}

.drmMeetingApproval__despBoxValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.683rem;
  line-height: 0.563rem;
  color: #2b2b2b;
  /* padding: 0.625rem; */
  /* text-align: center; */
}

/* .drmMeetingApproval__buttonBox {
  background: #f98d67;
  border-radius: 0.304rem;
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.313rem !important;
  border: 1px solid #f98d67;
} */

.drmMeetingApproval__buttonName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.634rem;
  line-height: 0.625rem;
  text-align: end;
  color: #ffffff;
  padding: 0.313rem;
  text-align: center;

}

.drmMeetingApproval__buttonValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.534rem;
  line-height: 0.625rem;
  text-align: start;
  color: #ffffff;
  padding: 0.313rem;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
}

@media (max-device-width: 335px) {
  .drmMeetingApproval__title {
    font-size: 0.7rem;
  }
}

.drmMeetingApproval__buttonBox {
  /* display: flex; */
  height: 1.763rem;
  border-radius: 0.16rem;
  margin-bottom: 0.313rem;
  padding: 0px !important;
  width: -webkit-fill-available;
  align-items: anchor-center;
}

.drmMeetingApproval__approveButton {
  background: #2cc26a !important;
  border-radius: 0.3rem !important;
  border-color: #2cc26a !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.8rem;
  white-space: nowrap;
  width: 20%;
  padding: 5px !important;
  margin: 0px 5px;
  float: right;
}

.drmMeetingApproval__rejectButton {
  background: #FF3131 !important;
  border-radius: 0.3rem !important;
  border-color: #FF3131 !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.8rem;
  white-space: nowrap;
  width: 20%;
  padding: 5px !important;
  margin: 0px 5px;
  float: right;
}

.drmMeetingApproval__saveBox {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.drmMeetingApproval__rejectTitle {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.875rem;
  /* color: #3901ff; */
}

.drmMeetingApproval__rejectLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #808080;
  text-align: start;
  /* width: 100%; */
  /* //border-bottom: 1px solid; */
}

.drmMeetingApproval__cancel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #3901ff;
  margin: 0px;
  align-self: center;
  width: 4.5rem;
  background: #fff !important;
  border: none !important;
  height: 1.7rem;
  place-self: center;
  justify-self: right;
  padding: inherit !important;
  margin-left: 15px;
}

.drmMeetingApproval__submit {
  font-size: 11.5px !important;
  margin-top: 0.3rem !important;
  color: #fff !important;
  width: 4.5rem;
  background: #3901ff !important;
  border: none !important;
  height: 1.7rem;
  place-self: center;
  justify-self: right;
  padding: inherit !important;
  border-radius: 5px !important;
  margin-bottom: 10px;
}

.drmMeetingApproval__remarkError {
  color: red;
  text-align: start;
  font-size: 11px;
}


.tourPlan__messageConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}