.meetingCard {
    border-radius: 10px;
}

.accordion-button {
    font-size: 0.9rem !important;
}

.accordion-body {
    font-size: 0.8rem !important;

}

.drm_button_success {
    background: #2cc26a !important;
    border: none !important;
    font-size: 0.7rem !important;
    padding: 2px 4px 2px 4px !important;
}

.drm_button_fail {
    background: #f27042 !important;
    border: none !important;
    font-size: 0.7rem !important;
    padding: 2px 4px 2px 4px !important;

}

.drm_imput {
    border: 1px solid gray !important;
}

.accordion-button {
    padding: 6px 10px 6px 10px !important;
}

.drmDetails__OuterBox{
    padding: 0.625rem 1rem !important;
}

.drmDetails__innerBox {
    background: #ffffff;
    box-shadow: 0.081rem 0.081rem 0.348rem rgb(20 20 20 / 7%);
    border-radius: 0.525rem;
    padding: 0.625rem;
}

.drmDetails__despBox {
    /* display: flex; */
    /* height: 1.763rem; */
    border-radius: 0.16rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 0px !important;
    width: -webkit-fill-available;
    align-items: anchor-center;
}

.drmDetails__despBoxName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.963rem;
    line-height: 0.563rem;
    color: #4b4b4b;
    padding: 0.313rem;
    text-wrap-mode: nowrap;
}

.drmDetails__despBoxValue {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.863rem;
    /* line-height: 0.563rem; */
    color: #acacac;
    padding: 0.625rem;
    /* text-align: center; */
}