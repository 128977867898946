.login {
    margin: 4rem 2rem 4rem 2rem;
}

.login__title {
    width: 210px;
    margin-bottom: 3rem;
}

.login__name {
    font-size: 30px;
    font-weight: 700;
    color: #3901FF !important;
    line-height: 29px;
}

.login__inputField input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #5A5A5A !important;
    width: 100%;
    height: 5rem;
    padding-top: 45px;
}

.login__inputField p {
    font-size: 10px;
    color: #e60000;
    font-weight: 500;
    margin-bottom: 0px !important;
}

.login__inputField ::placeholder {
    font-size: 15px;
    font-weight: 400;
}

.login__checkBoxForgot {
    font-size: 0.7rem;
    font-weight: 400;
    display: grid;
    grid-template-columns: 59% 41%;
    margin-top: -1rem;
}

.login__checkBox .form-check-input:focus {
    box-shadow: none !important;
    border: 1px solid #BCBCBC !important;
}

.login__checkBox .form-check-input:hover {
    box-shadow: none !important;
    border:1px solid #BCBCBC !important;
}

.login__checkBox label{
    color: #5A5A5A;
}

.login__forgotPassword {
    color: #5A5A5A;
    text-decoration: none;
    display: flex;
    justify-content: end;
    font-size: 0.7rem;
}

.login__submit {
    text-align: center;
    margin-top: 2rem;
}

.login__ssoBox {
    text-align: center;
    margin-top: 0rem;
}

.login__submitBtn {
    background: #3901FF !important;
    border-radius: 8.17286px !important;
    width: 200px;
    height: 39px;
    margin-bottom: 0.5rem;
}

.login__ssoSubmitBtn {
    background: #FF8C00 !important;
    border: #FF8C00 !important;
    border-radius: 8.17286px !important;
    width: 200px;
    height: 39px;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}

.login__domain {
    color: #3901FF;
    text-decoration: none;
    font-size: 9px;
    font-weight: 500;
}

.login__forgotPasswordBox .modalPopupSubTitle{
    display:block;
}

.login__forgotModal {
    margin: 1rem;
}

.login__modalInput {
    margin-top: 0.8rem;
    border: 2px solid #cccccc !important;
}
.login__resetHeading{
    padding-bottom: 1rem;
}

.login__inputBox{
    border: 0.043rem solid #808080;
    border-radius: 0.3rem;
    width: 100%;
    padding-left: 0.75rem;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.731rem;
    line-height: 0.688rem;
    color: #2B2B2B;
    height: 1.7rem;
    margin-bottom: 0.6rem;
  }

.login__modalBtn {
    display: flex;
    margin-top: 0.8rem;
    justify-items: center;
    justify-content: space-evenly;
}

.login__cancelBTn {

    background: none !important;
    border:none !important;
    color: #3901FF !important;
    width: 4rem;
    font-size: 12px !important;
    align-items: center;
    height: 1.7rem;
    padding-bottom: 1.5rem !important;
}

.login__resetBTn {
    background: #3901FF !important;
    border: #3901FF !important;
    width: 4rem;
    font-size: 14px !important;
    align-items: center;
    height: 1.7rem;
    padding-bottom: 1.5rem !important;
}

.passResetIcons .resetSmall {
    font-size: 1.5rem;
    color: #FFA60033;
    margin-top: 1rem;
}

.passResetIcons .resetLarge {
    font-size: 3rem;
    color: #2CC26A;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.passResetIcons {
    align-self: center;
    margin-top: 2rem;
}
.errorMsg{
    color:red;
    font-size:10px;
    text-align: start;
    margin-left: 2px;
}

@media screen and (min-width: 768px) {
    .login__forgotPassword {
        font-size: 0.9rem;
    }

    .login__checkBoxForgot{
        font-size: 0.9rem;
    }
  }

  .login_eye{
  /* margin-top: 3rem !important; */
  padding-bottom: 5px;
  height: 2em;
  /* border-bottom: 1px solid #5A5A5A !important; */
  padding-right: 10px;
  width: 1.5em;
  position: relative;
  left: 98%;
  bottom: 2rem;
  }

  .login__appVersion{
    margin-top: 10%;
    text-align: center;
    color: #949494;
    font-size: 12px;
    font-weight: 500;
    background: transparent;
  }
